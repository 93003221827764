exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-ai-tool-tsx": () => import("./../../../src/pages/ai-tool.tsx" /* webpackChunkName: "component---src-pages-ai-tool-tsx" */),
  "component---src-pages-app-privacy-policy-tsx": () => import("./../../../src/pages/app-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-app-privacy-policy-tsx" */),
  "component---src-pages-app-terms-of-use-tsx": () => import("./../../../src/pages/app-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-app-terms-of-use-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-content-creation-service-tsx": () => import("./../../../src/pages/content-creation-service.tsx" /* webpackChunkName: "component---src-pages-content-creation-service-tsx" */),
  "component---src-pages-customer-results-tsx": () => import("./../../../src/pages/customer-results.tsx" /* webpackChunkName: "component---src-pages-customer-results-tsx" */),
  "component---src-pages-email-sent-tsx": () => import("./../../../src/pages/email-sent.tsx" /* webpackChunkName: "component---src-pages-email-sent-tsx" */),
  "component---src-pages-free-demo-module-tsx": () => import("./../../../src/pages/free-demo-module.tsx" /* webpackChunkName: "component---src-pages-free-demo-module-tsx" */),
  "component---src-pages-help-center-article-tsx": () => import("./../../../src/pages/help-center-article.tsx" /* webpackChunkName: "component---src-pages-help-center-article-tsx" */),
  "component---src-pages-help-center-categories-tsx": () => import("./../../../src/pages/help-center-categories.tsx" /* webpackChunkName: "component---src-pages-help-center-categories-tsx" */),
  "component---src-pages-help-center-home-[tutorial-id]-tsx": () => import("./../../../src/pages/help-center-home/[tutorial-id].tsx" /* webpackChunkName: "component---src-pages-help-center-home-[tutorial-id]-tsx" */),
  "component---src-pages-help-center-home-tsx": () => import("./../../../src/pages/help-center-home.tsx" /* webpackChunkName: "component---src-pages-help-center-home-tsx" */),
  "component---src-pages-help-center-release-notes-tsx": () => import("./../../../src/pages/help-center-release-notes.tsx" /* webpackChunkName: "component---src-pages-help-center-release-notes-tsx" */),
  "component---src-pages-help-center-search-results-tsx": () => import("./../../../src/pages/help-center-search-results.tsx" /* webpackChunkName: "component---src-pages-help-center-search-results-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-consumer-electronics-tsx": () => import("./../../../src/pages/industry-consumer-electronics.tsx" /* webpackChunkName: "component---src-pages-industry-consumer-electronics-tsx" */),
  "component---src-pages-industry-luxury-goods-tsx": () => import("./../../../src/pages/industry-luxury-goods.tsx" /* webpackChunkName: "component---src-pages-industry-luxury-goods-tsx" */),
  "component---src-pages-industry-manufacturing-tsx": () => import("./../../../src/pages/industry-manufacturing.tsx" /* webpackChunkName: "component---src-pages-industry-manufacturing-tsx" */),
  "component---src-pages-industry-pharmaceuticals-tsx": () => import("./../../../src/pages/industry-pharmaceuticals.tsx" /* webpackChunkName: "component---src-pages-industry-pharmaceuticals-tsx" */),
  "component---src-pages-industry-telecommunication-tsx": () => import("./../../../src/pages/industry-telecommunication.tsx" /* webpackChunkName: "component---src-pages-industry-telecommunication-tsx" */),
  "component---src-pages-nurse-training-landing-page-tsx": () => import("./../../../src/pages/nurse-training-landing-page.tsx" /* webpackChunkName: "component---src-pages-nurse-training-landing-page-tsx" */),
  "component---src-pages-plasmic-host-tsx": () => import("./../../../src/pages/plasmic-host.tsx" /* webpackChunkName: "component---src-pages-plasmic-host-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-single-[blog-post]-blog-post-tsx": () => import("./../../../src/pages/single-blog-post/[blog-post].tsx" /* webpackChunkName: "component---src-pages-single-[blog-post]-blog-post-tsx" */),
  "component---src-pages-single-blog-post-tsx": () => import("./../../../src/pages/single-blog-post.tsx" /* webpackChunkName: "component---src-pages-single-blog-post-tsx" */),
  "component---src-pages-training-content-tsx": () => import("./../../../src/pages/training-content.tsx" /* webpackChunkName: "component---src-pages-training-content-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */),
  "component---src-pages-who-is-it-for-ceos-tsx": () => import("./../../../src/pages/who-is-it-for-ceos.tsx" /* webpackChunkName: "component---src-pages-who-is-it-for-ceos-tsx" */),
  "component---src-pages-who-is-it-for-employees-tsx": () => import("./../../../src/pages/who-is-it-for-employees.tsx" /* webpackChunkName: "component---src-pages-who-is-it-for-employees-tsx" */),
  "component---src-pages-who-is-it-for-instructional-designers-tsx": () => import("./../../../src/pages/who-is-it-for-instructional-designers.tsx" /* webpackChunkName: "component---src-pages-who-is-it-for-instructional-designers-tsx" */),
  "component---src-pages-who-is-it-for-managers-tsx": () => import("./../../../src/pages/who-is-it-for-managers.tsx" /* webpackChunkName: "component---src-pages-who-is-it-for-managers-tsx" */)
}

